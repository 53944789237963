import KeenSlider from "keen-slider";

function navigation(slider) {
  let wrapper, arrowLeft, arrowRight;

  const observer = new ResizeObserver(function () {
    slider.update();
  });

  function markup(remove) {
    wrapperMarkup(remove);
    arrowMarkup(remove);
  }

  function removeElement(elment) {
    elment.parentNode.removeChild(elment);
  }
  function createDiv(className) {
    var div = document.createElement("div");
    var classNames = className.split(" ");
    classNames.forEach((name) => div.classList.add(name));
    return div;
  }

  function arrowMarkup(remove) {
    if (remove) {
      removeElement(arrowLeft);
      removeElement(arrowRight);
      return;
    }
    arrowLeft = createDiv("arrow arrow--left");
    arrowLeft.addEventListener("click", () => slider.prev());
    arrowRight = createDiv("arrow arrow--right");
    arrowRight.addEventListener("click", () => slider.next());

    wrapper.appendChild(arrowLeft);
    wrapper.appendChild(arrowRight);
  }

  function wrapperMarkup(remove) {
    if (remove) {
      var parent = wrapper.parentNode;
      while (wrapper.firstChild)
        parent.insertBefore(wrapper.firstChild, wrapper);
      removeElement(wrapper);
      return;
    }
    wrapper = createDiv("navigation-wrapper");
    slider.container.parentNode.appendChild(wrapper);
    wrapper.appendChild(slider.container);
  }

  function updateClasses() {
    var slide = slider.track.details.rel;
    slide === 0
      ? arrowLeft.classList.add("arrow--disabled")
      : arrowLeft.classList.remove("arrow--disabled");
    slide === slider.track.details.slides.length - 1
      ? arrowRight.classList.add("arrow--disabled")
      : arrowRight.classList.remove("arrow--disabled");
  }

  slider.on("created", () => {
    markup();
    updateClasses();
    observer.observe(slider.container);
    hidePlaceholder(true);
  });
  slider.on("optionsChanged", () => {
    markup(true);
    markup();
    updateClasses();
  });
  slider.on("slideChanged", () => {
    updateClasses();
  });
  slider.on("destroyed", () => {
    markup(true);
    observer.unobserve(slider.container);
  });
}
var sliderEle = document.querySelector("#pc-slider");
//let rtlView = window.Shopify.locale == "ar" ? true : false;
if (sliderEle) {
  var slider = new KeenSlider(
    "#pc-slider",
    {
      loop: true,
      //rtl: rtlView,
      slides: {
        spacing: 10,
        perView: 2.3,
      },
      breakpoints: {
        "(min-width: 768px)": {
          slides: {
            perView: 5,
            spacing: 24,
          },
        },
        "(min-width: 1200px)": {
          slides: {
            perView: 5,
            spacing: 64,
          },
        },
      },
    },
    [navigation]
  );
}

function hidePlaceholder(action) {
  if (action) {
    let placeholderEle = document.querySelector("#pc-placeholder");
    let sliderEle = document.querySelector("#pc-slider");
    placeholderEle.classList.add("hide");
    sliderEle.classList.remove("hide");
  }
}
